
/* global ActiveXObject */
import { breakpoints } from '../variables.yml';

import 'focus-visible';
// import './vendor/lib.js';
// import { whatever } from './vendor/lib.js';
import { getWindowWidth } from './helpers.js';
import { HandleTabs } from './vendor/HandleTabs.js';
import yall from 'yall-js';
import EmblaCarousel from 'embla-carousel';
import { rebindFormElems } from './vendor/collapsed-form-items.js';
import { Formulator } from './formulator.js';

export { Formulator };
export * from './CookieConsentManager.js';

( () => {
	rebindFormElems();
} )();

( () => {
	// Lazy loading images
	document.addEventListener( 'DOMContentLoaded', () => {
		yall( {
			observeChanges: true,
			lazyClass: 'lazy--loading',
		} );
	} );
} )();

( () => {
	// Scroll to top
	const button = document.querySelector( '.js-scroll-to-top' );
	button.addEventListener( 'click', scrollToTop );

	function scrollToTop() {
		const root = document.documentElement;
		root.scrollTo( {
			top: 0,
			behavior: 'smooth',
		} );
	}
} )();

( () => {
	// Navigation
	/* eslint-disable prefer-arrow-callback */
	const menu_items = document.querySelectorAll( '.has-submenu' );
	const open = document.querySelector( '.header__menu-toggle' );
	const close = document.querySelector( '.header__menu-button--close' );
	const back = document.querySelector( '.header__menu-button--back' );
	let prevent_default = true;

	window.addEventListener( 'load', () => {
		handlePreventDefault();
	} );

	window.addEventListener( 'resize', () => {
		handlePreventDefault();
	} );

	function handlePreventDefault() {
		if ( getWindowWidth() >= breakpoints.e.width ) {
			prevent_default = false;
			return;
		}
		prevent_default = true;
		return;
	}

	Array.prototype.forEach.call( menu_items, function( item ) {
		let timer = null;
		const link = item.querySelector( 'a' );
		if ( getWindowWidth() > breakpoints.f.width ) {
			item.addEventListener( 'mouseover', function() {
				for ( let i = 0; i < menu_items.length; i++ ) {
					menu_items[i].classList.remove( 'open' );
					link.setAttribute( 'aria-expanded', false );
				}
				this.classList.add( 'open' );
				document.body.classList.add( 'nav-open' );
				link.setAttribute( 'aria-expanded', true );
				clearTimeout( timer );
			} );
			item.addEventListener( 'mouseout', function() {
				timer = setTimeout( function() {
					if ( document.querySelector( '.has-submenu.open' ) ) {
						document.querySelector( '.has-submenu.open' ).classList.remove( 'open' );
						document.body.classList.remove( 'nav-open' );
					}
					link.setAttribute( 'aria-expanded', false );
				}, 1000 );
			} );
		}
		item.querySelector( 'a' ).addEventListener( 'click', function( e ) {
			if ( prevent_default ) {
				e.preventDefault();
			}
			if ( this.parentNode.classList.contains( 'open' ) ) {
				this.parentNode.classList.remove( 'open' );
				this.setAttribute( 'aria-expanded', false );
			} else {
				document.body.classList.add( 'subnav-open' );
				this.parentNode.classList.add( 'open' );
				this.setAttribute( 'aria-expanded', true );
			}
			return false;
		} );
	} );

	back.addEventListener( 'click', () => {
		document.querySelector( '.has-submenu.open' ).classList.remove( 'open' );
		if ( document.body.classList.contains( 'subnav-open' ) ) {
			document.body.classList.remove( 'subnav-open' );
		}
	} );

	open.addEventListener( 'click', toggleMenu );
	close.addEventListener( 'click', toggleMenu );
	window.addEventListener( 'resize', () => {
		if ( getWindowWidth() > breakpoints.e.f && document.body.classList.contains ( 'menu-open' ) ) {
			document.body.classList.remove( 'menu-open' );
		}
	} );

	function toggleMenu() {
		if ( document.body.classList.contains ( 'menu-open' ) ) {
			document.body.classList.remove( 'menu-open' );
			return;
		}
		document.body.classList.add( 'menu-open' );
	}

} )();

( () => {
	// Set up accessible tabs
	const tab_groups = document.querySelectorAll( '.js-tabs-group' );
	tab_groups.forEach( group => {
		new HandleTabs( group );
	} );
} )();

( () => {
	// Video embeds
	function buildVideoPlayer( container ) {
		const embed = document.querySelector( '.video-embed' );
		const embed_url = embed.getAttribute( 'data-embed-url' );
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'width', 1640 );
		iframe.setAttribute( 'height', 923 );
		iframe.setAttribute( 'src', embed_url );
		iframe.setAttribute( 'title', 'Embedded video content' );
		iframe.setAttribute( 'frameborder', 0 );
		iframe.setAttribute( 'allowfullscreen', true );
		iframe.setAttribute( 'allow', 'autoplay' );
		container.classList.add( 'flex-container' );
		embed.classList.add( 'video-embed--playing' );
		container.innerHTML = '';
		container.appendChild( iframe );
	}

	function handleVideoClick( container ) {
		return ( event ) => {
			event.preventDefault();
			buildVideoPlayer( container );
		};
	}

	const videos = document.querySelectorAll( '.video-container' );
	for ( let j = 0; j < videos.length; j += 1 ) {
		videos[j].addEventListener( 'click', handleVideoClick( videos[j] ) );
	}
} )();

( () => {
	// Embla carousel
	const embla_node = document.querySelector( '.content-slider__container' );
	if ( !embla_node ) {
		return;
	}

	const options = {
		align: 'start',
		loop: false,
		containScroll: 'trimSnaps',
	};

	const embla = EmblaCarousel( embla_node, options );
	const prev = document.querySelector( '.content-slider__arrow--prev' );
	const next = document.querySelector( '.content-slider__arrow--next' );
	const progress_bar = document.querySelector( '.content-slider__progress-bar' );
	const disable_prev_next_btns = disablePrevNextBtns( prev, next, embla );
	const update_progress = updateProgressBar( progress_bar, embla );

	setupPrevNextBtns( prev, next, embla );

	embla.on( 'select', disable_prev_next_btns );
	embla.on( 'scroll', update_progress );

	function setupPrevNextBtns( btn_prev, btn_next, embla ) {
		btn_prev.addEventListener( 'click', embla.scrollPrev, false );
		btn_next.addEventListener( 'click', embla.scrollNext, false );
	}

	function disablePrevNextBtns( btn_prev, btn_next, embla ) {
		return () => {
			if ( embla.canScrollPrev() ) {
				btn_prev.removeAttribute( 'disabled' );
				btn_prev.classList.remove( 'content-slider__arrow--disabled' );
			} else {
				btn_prev.setAttribute( 'disabled', 'disabled' );
				btn_prev.classList.add( 'content-slider__arrow--disabled' );
			}

			if ( embla.canScrollNext() ) {
				btn_next.removeAttribute( 'disabled' );
				btn_next.classList.remove( 'content-slider__arrow--disabled' );
			} else {
				btn_next.setAttribute( 'disabled', 'disabled' );
				btn_next.classList.add( 'content-slider__arrow--disabled' );
			}
		};
	}

	function updateProgressBar( progress_bar, embla ) {
		return () => {
			const progress = Math.max( 0, Math.min( 1, embla.scrollProgress() ) );
			progress_bar.style.transform = `translateX(${progress * 100}%)`;
		};
	}

} )();

( () => {
	// Hide groups
	// Used for showing/hiding form fields depending on a checkbox. All children
	// with the same key as the parent will get display:none when the parent is
	// checked.
	// All other parents with the same group will get disabled when the parent
	// is checked.
	function inputIsChecked( element ) {
		if ( element instanceof HTMLSelectElement ) {
			return element.selectedOptions[0].hasAttribute( 'data-checked' );
		}
		return element.checked;
	}
	function updateHiddenThings( event ) {
		const hide_status = {};
		const hide_parents = document.querySelectorAll( '[data-hide-key]' );
		const hide_children = document.querySelectorAll( '[data-hide-parent]' );
		for ( let i = 0; i < hide_parents.length; i++ ) {
			const key = hide_parents[i].getAttribute( 'data-hide-key' );
			if ( !Object.prototype.hasOwnProperty.call( hide_status, key ) ) {
				hide_status[key] = false;
			}
			hide_status[key] = hide_status[key] || inputIsChecked( hide_parents[i] );
			const group = hide_parents[i].getAttribute( 'data-hide-group' );
			if ( group === null ) {
				continue;
			}
			if ( event && group !== event.target.getAttribute( 'data-hide-group' ) ) {
				continue;
			}
			if ( event && hide_parents[i] === event.target ) {
				continue;
			}
			if ( event && inputIsChecked( event.target ) ) {
				hide_parents[i].disabled = true;
				continue;
			}
			hide_parents[i].disabled = false;
		}
		for ( let j = 0; j < hide_children.length; j++ ) {
			const keys = hide_children[j].getAttribute( 'data-hide-parent' ).split( '|' );
			const disable = hide_children[j].hasAttribute( 'data-hide-disable' );
			const opposite = hide_children[j].hasAttribute( 'data-hide-opposite' );
			const autoselect_radio = hide_children[j].hasAttribute( 'data-hide-autoselect-radio' );
			let child_hide_status = false;
			for ( let k = 0; k < keys.length; k++ ) {
				if ( !Object.prototype.hasOwnProperty.call( hide_status, keys[k] ) ) {
					continue;
				}
				child_hide_status = child_hide_status || hide_status[keys[k]];
			}
			if ( disable ) {
				hide_children[j].disabled = child_hide_status;
				continue;
			}
			if ( autoselect_radio ) {
				let first_radio = hide_children[j].querySelector( '[data-autoselect]' );
				if ( !first_radio ) {
					first_radio = hide_children[j].querySelector( 'input[type=radio]' );
				}
				if ( child_hide_status && event && event.target.name !== first_radio.name ) {
					const parents = [];
					for ( let l = 0; l < keys.length; l++ ) {
						const parent = document.querySelector( '[data-hide-key=' + keys[l] + ']' );
						if ( !parent ) {
							continue;
						}
						parents.push( parent );
					}
					if ( parents.indexOf( event.target ) > -1 ) {
						first_radio.checked = true;
						window.setTimeout( () => {
							let e;
							if ( typeof( Event ) === 'function' ) {
								e = new Event( 'change', { bubbles: true } );
							} else { // IE11
								e = document.createEvent( 'Event' );
								e.initEvent( 'submit', true, false );
							}
							first_radio.dispatchEvent( e );
						}, 1 );
					}
				}
			}
			if ( opposite ) {
				hide_children[j].style.display = ( child_hide_status ? '' : 'none' );
				continue;
			}
			hide_children[j].style.display = ( child_hide_status ? 'none' : '' );
		}
	}
	window.addEventListener( 'change', updateHiddenThings );
	updateHiddenThings();
} )();

( () => {
	// Async feeds
	function handleAjaxSuccess( feed_container ) {
		return ( data ) => {
			feed_container.classList.add( 'external-feed--loaded' );
			feed_container.closest( '.async' ).classList.remove( 'async--submitting' );
			feed_container.innerHTML = data;
		};
	}

	function handleAjaxError( feed_container ) {
		return () => {
			feed_container.classList.add( 'external-feed--loaded' );
			feed_container.closest( '.async' ).classList.remove( 'async--submitting' );
			feed_container.innerHTML = '<p class="p">Feed failed to load.</p>';
		};
	}

	const feed_containers = document.querySelectorAll( '.external-feed' );
	for ( let i = 0; i < feed_containers.length; i += 1 ) {
		const url = feed_containers[i].getAttribute( 'data-feed-url' );
		if ( !url ) {
			continue;
		}
		fetch( url, {
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
			},
		} )
			.then( response => response.text() )
			.then( handleAjaxSuccess( feed_containers[i] ) )
			.catch( handleAjaxError( feed_containers[i] ) );
	}
} )();

( () => {
	// Dynamic amount field and Gift Aid calculation
	const amount = document.getElementById( 'amount' );
	const donation_amount_receptacle = document.getElementById( 'js-donation-amount' );
	const gift_aid_amount_receptacle = document.getElementById( 'js-gift-aid-amount' );
	const amount_populators = document.querySelectorAll( '.form__amount-button' );
	const donation_widget_amount_description = document.querySelector( '.donation-widget__amount-description' );

	if ( !amount ) {
		return;
	}

	const url_search_params = new URLSearchParams( window.location.search );
	const preexisting_amount = url_search_params.get( 'amount' );
	if ( !preexisting_amount || preexisting_amount <= 0  ) {
		highlightDefaultAmount();
	}

	function normaliseAmount( amount ) {
		amount = parseFloat( amount );
		if ( isNaN( amount ) || amount < 0 ) {
			return 0;
		}
		return amount;
	}

	function updateGiftAidNumbers() {
		if ( !donation_amount_receptacle || !gift_aid_amount_receptacle ) {
			return;
		}
		const original_amount = normaliseAmount( amount.value );
		const gift_aid_amount = original_amount * 0.25;
		donation_amount_receptacle.innerHTML = ' £' + original_amount.toFixed( 2 ) + ' ';
		gift_aid_amount_receptacle.innerHTML = ' £' + gift_aid_amount.toFixed( 2 ) + ' (25%) ';
	}

	function highlightPopulators() {
		const current_amount = normaliseAmount( amount.value );
		for ( let i = 0; i < amount_populators.length; i++ ) {
			const value = normaliseAmount(
				amount_populators[i].getAttribute( 'data-populate-donation-amount' )
			);
			if ( value === current_amount ) {
				amount_populators[i].classList.add( 'form__amount-button--selected' );
				continue;
			}
			amount_populators[i].classList.remove( 'form__amount-button--selected' );
		}
	}

	function highlightDefaultAmount() {
		amount.value = normaliseAmount(
			amount_populators[0].getAttribute( 'data-populate-donation-amount' )
		);
		updateAmountDescription( amount_populators[0] );

		const donation_form = document.getElementById( 'js-donate' );
		const frequency_elements = donation_form.querySelectorAll( 'input[name="frequency"]' );
		let frequency = getFrequency();
		frequency_elements.forEach( function( element ) {
			element.addEventListener( 'click', ( e ) => {
				frequency = e.target.value;
				for ( let i = 0; i < amount_populators.length; i++ ) {
					if ( frequency === amount_populators[i].dataset.amountFrequency && parseInt( amount_populators[i].id ) === 0  ) {
						const value = normaliseAmount(
							amount_populators[i].getAttribute( 'data-populate-donation-amount' )
						).toFixed( 2 );
						amount.value = value;
						updateAmountDescription( amount_populators[i] );
						highlightPopulators();
						updateGiftAidNumbers();
					}
				}
			} );
		} );
	}

	amount.addEventListener( 'change', () => {
		updateAmountDescription( null );
		highlightPopulators();
		updateGiftAidNumbers();
	} );

	for ( let i = 0; i < amount_populators.length; i++ ) {
		amount_populators[i].addEventListener( 'click', ( ( populator ) => {
			const value = normaliseAmount(
				populator.getAttribute( 'data-populate-donation-amount' )
			).toFixed( 2 );
			return () => {
				amount.value = value;
				updateAmountDescription( populator );
				highlightPopulators();
				updateGiftAidNumbers();
			};
		} )( amount_populators[i] ) );
	}

	highlightPopulators();
	updateGiftAidNumbers();

	// Donation widget - Dynamic amount description and widget routing to donation page
	function updateAmountDescription( populator ) {
		if ( !donation_widget_amount_description ) {
			return;
		}

		if ( populator == null ) {
			donation_widget_amount_description.innerHTML = '';
		} else {
			donation_widget_amount_description.innerHTML = '<p>' + populator.dataset.amountDescription + '</p>';
			donation_widget_amount_description.classList.remove( 'donation-widget__amount-description--error-message' );
		}
	}

	routeWidgetToDonationPage();

	function getFrequency() {
		const frequency_elements = document.querySelectorAll( 'input[name="frequency"]' );
		let frequency = frequency_elements[0].value;
		frequency_elements.forEach( function( element ) {
			if ( element.checked ) {
				frequency = element.value;
			}
		} );
		return frequency;
	}

	function showErrorMessage() {
		if ( !donation_widget_amount_description ) {
			return;
		}
		donation_widget_amount_description.innerText = 'Please enter a valid amount.';
		donation_widget_amount_description.classList.add( 'donation-widget__amount-description--error-message' );
	}

	function routeWidgetToDonationPage( ) {
		const donation_widget_submit = document.querySelector( '.donation-widget__submit' );
		if ( !donation_widget_submit ) {
			return;
		}
		const base_url = window.location.protocol + '//' + window.location.host + '/donate/donate-online?';
		donation_widget_submit.addEventListener( 'click', ( ) => {
			const frequency = getFrequency();
			const current_amount = normaliseAmount( amount.value );
			if ( current_amount === null || current_amount <= 0 ) {
				showErrorMessage();
			} else if ( current_amount !== null && current_amount > 0 ) {
				if ( /^\d{0,3}(?:\.\d*)?$/.test( current_amount ) ) {
					window.location.href = base_url + 'amount=' + current_amount + '&frequency=' + frequency;
				} else {
					showErrorMessage();
				}
			}
		} );
	}

} )();

( () => {
	// Header search
	const search_toggle = document.getElementById( 'js-search-toggle' );
	const search_input = document.getElementById( 'js-search-input' );

	search_toggle.addEventListener( 'click', () => {
		if ( document.body.classList.contains( 'search-open' ) ) {
			document.body.classList.remove( 'search-open' );
			return;
		}
		document.body.classList.add( 'search-open' );
		window.setTimeout( () => {
			// Focus after transition
			search_input.focus();
		}, 400 );
	} );

	window.addEventListener( 'keyup', ( e ) => {
		if ( e.key === 'Escape' || e.keyCode === 27 ) { // escape key maps to keycode `27`
			document.body.classList.remove( 'search-open' );
		}
	} );
} )();

( () => {
	// Filters
	const filter_form = document.getElementById( 'js-filters' );
	const filter_button = document.getElementById( 'js-filter-toggle' );
	const filter_menu = document.getElementById( 'js-filter-menu' );
	const results_container = document.getElementById( 'js-filter-results' );
	const pagination_container = document.getElementById( 'pagination' );
	const keyword_form = document.getElementById( 'js-keyword-search' );

	if ( !filter_form || !results_container || !filter_button || !filter_menu ) {
		return;
	}

	filter_button.addEventListener( 'click', () => {
		if ( document.body.classList.contains( 'show-filter-menu' ) ) {
			document.body.classList.remove( 'show-filter-menu' );
			return;
		}
		document.body.classList.add( 'show-filter-menu' );
	} );

	filter_form.addEventListener( 'change', () => {
		filterFormQuery( filter_form );
	} );

	filter_form.addEventListener( 'submit', ( event ) => {
		event.preventDefault();
		filterFormQuery( filter_form );
	} );

	let request = '';

	if ( keyword_form ) {
		keyword_form.addEventListener( 'submit', ( event ) => {
			event.preventDefault();
			filterFormQuery( keyword_form );
		} );
	}

	function sendFilteredContent( url ) {
		if ( window.XMLHttpRequest ) {
			request = new XMLHttpRequest();
		} else if ( window.ActiveXObject ) {
			request = new ActiveXObject( 'Microsoft.XMLHTTP' );
		}

		try {
			request.onreadystatechange = getFilteredContent;
			request.open( 'GET', url, true );
			request.setRequestHeader( 'X-Requested-With', 'XMLHTTPReqest' );
			request.send();
		} catch ( e ) {
			alert( 'Unable to connect to server' );
		}
	}

	function getFilteredContent() {
		results_container.innerHTML = '';
		pagination_container.innerHTML = '';

		if ( request.readyState === 4 && request.status === 200 ) {
			const parser = new DOMParser();
			const new_results_container = parser.parseFromString( request.responseText, 'text/html' );
			const new_results_content = new_results_container.getElementById( 'js-filter-results' );
			const new_pagination_content = new_results_container.getElementById( 'pagination' );
			if ( new_results_content ) {
				pagination_container.innerHTML = new_pagination_content.innerHTML;
				results_container.innerHTML = new_results_content.innerHTML;
			} else {
				results_container.innerHTML = '<p>Sorry, unable to load</p>';
			}
		}
	}

	function filterFormQuery( form ) {
		const existing_params = new URLSearchParams( window.location.search );
		const form_data = new FormData( form );
		const search = new URLSearchParams( form_data );
		search.forEach( ( value, key ) => {
			if ( !existing_params.has( key, value )  ) {
				existing_params.append( key, value );
			} else {
				existing_params.delete( key, value );
			}
		} );

		const query_string = search.toString();
		const url = `${window.location.pathname}?${query_string}`;

		window.history.pushState( null, null, decodeURI( url ) );

		sendFilteredContent( url );
	}

} )();

